<div class="container" fxLayout="column" fxLayoutGap="1rem" fxLayoutAllign="space-around">
  <div>
    <p *ngIf="this.currentuser" class="mat-caption lightgreycolor right">
      Angemeldet als <span> {{this.currentuser.anrede?this.currentuser.anrede+"
        "+this.currentuser.lastname:"" }}</span></p>
    <div *ngIf="!isExtern" class="brandingpicture"
      [ngClass]="{'smallheader': this.currentuser || this.summaryinfo['done']}">
      <h1>Akut- und Notfälle bei der 116117</h1>
    </div>
    <div *ngIf="isExtern" class="brandingpicture"
      [ngClass]="{'smallheader': this.currentuser || this.summaryinfo['done']}">
      <h1>Ersteinschätzung von Akut- und Notfällen mit SmED</h1>
    </div>
    <ng-container *ngIf="this.metadata && !this.currentuser">
      <!-- Text anpassen -->
      <ng-container *ngIf="(this.timetogo<0)">
        <p>Auf diesem Portal stellt das Zentralinstitut für die kassenärztliche Versorgung in Deutschland (<strong><a
              style="color:white;" href="https://www.zi.de">Zi</a></strong>) Informationen
          zu
          ambulanten Akut- und Notfällen in Deutschland zur Verfügung. Aktuell basieren die Auswertungen auf den
          SmED-Daten,
          die von den 116117-Leitstellen der Kassenärztlichen Vereinigungen übermittelt wurden. Bei
          <strong>SmED</strong> (Strukturierte
          medizinische Ersteinschätzung in Deutschland) handelt es sich um eine Ersteinschätzungssoftware, die den
          Anwender
          bei der Einschätzung der Beschwerden eines Patienten unterstützen kann. Durch <strong>SmED</strong> wird das
          medizinische
          Fachpersonal bei der systematischen Abfrage von Symptomen in Kombination mit allgemeinen und
          symptomspezifischen
          Risikofaktoren (z.B. Alter, Geschlecht, Begleitbeschwerden) sowie bei der Entscheidung, welches die passende
          Versorgungsmöglichkeit für den Anrufenden ist, unterstützt. Die SmED-Empfehlungen der Software stellen dabei
          eine
          Entscheidungshilfe für den Anwender dar. Weitere Informationen zu <strong>SmED</strong> finden Sie
          <strong><a href="https://www.zi.de/smed">hier</a></strong>.
        </p>
        <p>Zukünftig soll das Portal um Informationen aus weiteren Quellen (wie beispielsweise die Abrechnungsdaten) für
          ambulante Akut- und Notfälle ergänzt werden.</p>
        <h1 class="lightgreycolor" *ngIf="this.timetogo<=0">Zeit bis zum Start<br>{{counterstring(this.timetogo)}}</h1>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(this.timetogo>=0) || this.currentuser">
      <ng-container *ngIf="((this.timetogo>=0) || this.currentuser) && !this.isExtern">
        <p class="lightgreycolor"> Dashboard des Zentralinstituts für die kassenärztliche Versorgung in Deutschland
          (<strong><a style="color:white;" href="https://www.zi.de">Zi</a></strong>) mit aktuellen Ergebnissen aus der
          Strukturierten medizinischen Ersteinschätzung in Deutschland
          (<strong><a style="color:white;" href="https://www.zi.de/smed">SmED</a></strong>) im Rahmen von Anrufen bei
          der
          Rufnummer (<strong><a style="color:white;" href="https://www.116117.de">116117</a></strong>). Bei jeder
          Ersteinschätzung werden die Inhalte und Empfehlungen strukturiert protokolliert (Assessments). <span
            *ngIf="this.summaryinfo && this.levelsettings['resolution']=='weekly'">Daten für vollständige
            Kalenderwochen beginnend mit der Woche vom {{this.summaryinfo["Beginn"] | date}} bis zur Woche beginnend am
            {{this.summaryinfo["Ende"] | date}}.</span></p>
        <p class="lightgreycolor" *ngIf="this.currentuser">Als registrierter Nutzer steht Ihnen unser <a
            routerLink="/private">interner Analysebereich</a> zur Verfügung.</p>
      </ng-container>
      <ng-container *ngIf="((this.timetogo>=0) || this.currentuser) && this.isExtern">
        <p class="lightgreycolor"> Dashboard mit aktuellen Ergebnissen aus der Strukturierten medizinischen
          Ersteinschätzung in Deutschland (SmED) im Rahmen der Anwendung am eigenen Standort. </p>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="(this.timetogo>=0) || this.currentuser">
    <div *ngIf="this.currentuser">
      <div *ngIf="this.isExtern; then rkiUser else kvUser"></div>
      <ng-template #kvUser>
        <h3>Bundesland</h3>
        <p>
          <mat-chip-list>
            <mat-chip (click)="this.setlevel('levelvalues',item);" *ngFor="let item of this.levelvalues"
              [selected]="(this.levelsettings['levelvalues']==item)" color="primary">
              {{item.replace('KV ','')}}
            </mat-chip>
          </mat-chip-list>
        </p>
      </ng-template>
      <ng-template #rkiUser>
        <h3>Einsatzort</h3>
        <p>
          <mat-chip-list>
            <mat-chip (click)="this.setlevel('levelvalues',item); chipSelectedIndex = index;"
              *ngFor="let item of this.externLevelValues; let index = index" [selected]="index === chipSelectedIndex"
              color="primary">
              {{item}}
            </mat-chip>
          </mat-chip-list>
        </p>
      </ng-template>
    </div>
    <h3>Analysezeitraum</h3>
    <p>
      <mat-chip-list>
        <mat-chip (click)="this.setlevel('zeitraum',item);" *ngFor="let item of this.zeitaumoptions"
          [selected]="(this.levelsettings['zeitraum']==item)" color="primary">
          {{item}}
        </mat-chip>
      </mat-chip-list>
    </p>
    <div>
      <h3>Zeitliche Auflösung</h3>
      <p>
        <mat-chip-list>
          <mat-chip (click)="this.setlevel('resolution','weekly');"
            [selected]="(this.levelsettings['resolution']=='weekly')" color="primary">
            Kalenderwochen
          </mat-chip>
          <mat-chip (click)="this.setlevel('resolution','monthly');"
            [selected]="(this.levelsettings['resolution']=='monthly')" color="primary">
            Monate
          </mat-chip>
        </mat-chip-list>
      </p>
    </div>
    <div *ngIf="this.progress">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!this.summaryinfo && !this.progress">
      <p>
        Keine Daten verfügbar
      </p>
    </div>
    <div *ngIf="this.summaryinfo['done']" fxLayout="row wrap">
      <mat-card *ngIf="this.summaryinfo['Assessments Gesamt']" class="key-wrapper">
        <div class="card-wrapper">
          <app-box class="app-box" [value]="this.summaryinfo['Assessments Gesamt']" [textbehind]="true" title=""
            [numberformat]="'1.0-0'" [maincolor]="this.colorsscheme[4].concat('80')"
            [description]="'Gesamtanzahl der Assessments'"></app-box>
          <app-box class="app-box" [textbehind]="true" title="" [numberformat]="'1.1-1'" [maincolor]="this.colorsscheme[3].concat('80')"
            [value]="this.summaryinfo['Mittlere Dauer']" description="Mittlere Dauer pro Assessment (in Sekunden)"></app-box>
          <app-box class="app-box" [textbehind]="true" title="" [numberformat]="'1.1-1'"
            [maincolor]="this.colorsscheme[2].concat('80')" [value]="this.summaryinfo['Anzahl Beschwerden']"
            description="Mittlere Anzahl Beschwerden pro Asssessment">
          </app-box>
          <app-box class="app-box" [textbehind]="true" title="" [numberformat]="'1.1-1'"
            [maincolor]="this.colorsscheme[1].concat('80')" [value]="this.summaryinfo['Anzahl Fragen']"
            description="Mittlere Anzahl beantworteter Fragen pro Asssessment">
          </app-box>
        </div>
      </mat-card>
      <div fxFlex=50 fxFlex.lt-md=100 *ngIf="this.stats_ts">
        <mat-card>
          <div class="sliderHeader">
            <h3>SmED Assessments </h3>
            <mat-slide-toggle *ngIf="!this.isExtern" input [(ngModel)]="absoluteNumbers">Absolute
              Zahlen</mat-slide-toggle>
          </div>
          <br><span *ngIf="!absoluteNumbers" class="lightgreycolor">pro 100.000 Einwohner</span>
          <span class="lightgreycolor" *ngIf="absoluteNumbers">Absolute Zahlen</span>
          <app-plot [(data)]="this.stats_ts" xtickformat="%d.%m.<br>%Y" [xvalue]="'Datum'"
            [outcomes]="!absoluteNumbers ? ['Assessments pro 100 Tsd. Einw.'] : ['totaleNumbers']" [sortx]="true"
            [fontcolor]="'white'" plottype="area"></app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <div class="mat-caption">
                <p *ngIf="this.currentuser">
                  <a (click)="this.exportascsv('zeitreihe.csv',this.stats_ts)">
                    <mat-icon inline=true>file_download</mat-icon>
                    Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                  </a>
                </p>
                <h3> Weitere Informationen zum Indikator</h3>
                <p></p>
                <p *ngIf="!absoluteNumbers && !isExtern">Anzahl der SmED-Assessments pro 100.000 Einwohner, die im
                  Rahmen von Anrufen
                  bei der 116117 {{ this.levelsettings['resolution'] === 'weekly' ? 'wöchentlich' : 'monatlich' }}
                  durchgeführt wurden.</p>
                <p *ngIf="absoluteNumbers && !isExtern">
                  Anzahl der SmED-Assessments (in Tausend), die im Rahmen von Anrufen
                  bei der 116117 {{ this.levelsettings['resolution'] === 'weekly' ? 'wöchentlich' : 'monatlich' }}
                  durchgeführt wurden.
                </p>
                <p *ngIf="this.isExtern">
                  Anzahl der SmED-Assessments pro {{ this.levelsettings['resolution'] === 'weekly' ? 'Kalenderwoche' :
                  'Monat' }}.
                </p>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=50 fxFlex.lt-md=100 *ngIf="this.stats_ts && (!this.isExtern)">
        <mat-card>
          <h3>Aktivität respiratorischer Erkrankungen<br><span class="lightgreycolor">Abweichung von der
              Hintergrundaktivität in Prozent</span></h3>
          <app-plot [(data)]="this.stats_ts" xtickformat="%d.%m.<br>%Y" [xvalue]="'Datum'"
            [outcomes]="['Anteil ARE Assessments']" [sortx]="true" [fontcolor]="'white'" [colorscheme]="['crimson']"
            plottype="area"></app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <div class="mat-caption">
                <p *ngIf="this.currentuser">
                  <a (click)="this.exportascsv('zeitreihe.csv',this.stats_ts)">
                    <mat-icon inline=true>file_download</mat-icon>
                    Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                  </a>
                </p>
                <h3> Weitere Informationen zum Indikator</h3>
                <p>Anzahl der Assessments mit typischen Symptomen von Akuten Respiratorischen Erkrankungen (ARE)
                  ausgewiesen als prozentuale Abweichung von ARE Hintergrundaktivität (erwartet werden 25%
                  ARE-Assessments).
                  Berücksichtigt wurden folgende SmED-Beschwerdebilder: Atembeschwerden, Covid 19, Erkältung/Grippaler
                  Infekt, Fieber, Hals-/Rachenschmerzen, Husten, Kopfschmerzen, Ohrenschmerzen und Schluckbeschwerden
                  (Liste ist aktuell noch in Entwicklung, Änderungen sind möglich).</p>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div [fxFlex]="isExtern ? 50 : 100" fxFlex.lt-md=100 *ngIf="this.symptoms_list">
        <mat-card *ngIf="this.symptoms_list.length>0">
          <h3>Häufigste Beschwerden</h3>
          <br>
          <span class="lightgreycolor">Anteil Assessments mit angezeigter Beschwerde an allen Assessments in
            Prozent</span>
          <app-plot [(data)]="this.symptoms_list" [xvalue]="'Sympt'" [percent]="true" [fontcolor]="'white'"
            (clicked)="handleklick('Beschwerden',$event);" [outcomes]="['Anteil']" [sort]="true"
            plottype="hbar"></app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('symptome.csv',this.symptoms_list_export)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Darstellung der prozentuellen Häufigkeit der im Rahmen der SmED-Assessments dokumentierten
                    Beschwerden. Die Beschwerden sind sortiert nach der Häufigkeit und werden anteilig an allen
                    SmED-Assessments, in denen eine Angabe zur Beschwerde vorliegt, angezeigt. Insgesamt sind über 100
                    verschiedene Beschwerden über SmED verzeichnet worden. Bisher nicht enthalten sind Beschwerden, die
                    im Rahmen der Abfrage von lebensbedrohlichen Situationen (Vortriage) erfasst wurden.</p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=100 fxFlex.lt-md=100 *ngIf="this.utiltimes['z']">
        <ng-container *ngIf="this.utiltimes['z'].length>0">
          <mat-card>
            <h3>Aufkommen nach Tag und Uhrzeit<br><span class="lightgreycolor">Anteil Assessments zum angegebenen
                Zeitintervall an allen Assessments in Prozent</span>
              <span class="lightgreycolor mat-caption">(grün:hoch, blau:niedrig)</span>
            </h3>
            <app-plot [(data)]="this.utiltimes" [fontcolor]="'white'" plottype="heatmap">
            </app-plot>
            <div>
              <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
                (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
                </mat-expansion-panel-header>
                <mat-panel-description>
                  <div class="mat-caption">
                    <p *ngIf="this.currentuser">
                      <a (click)="this.exportascsv('zeiten.csv',this.utiltimes)">
                        <mat-icon inline=true>file_download</mat-icon>
                        Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                      </a>
                    </p>
                    <h3> Weitere Informationen zum Indikator</h3>
                    <p>Prozentuale Verteilung der SmED-Assessments nach Wochentag und Uhrzeit
                      (Drei-Stunden-Intervalle).​
                      Grüne Flächen zeigen ein hohes Aufkommen, blaue ein niedriges Aufkommen an durchgeführten
                      Assessments.
                    </p>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel>
            </div>
          </mat-card>
        </ng-container>
      </div>
      <div fxFlex=100 fxFlex.lt-md=100 *ngIf="this.timetotreat">
        <mat-card *ngIf="this.timetotreat.length>0">
          <h3>SmED Empfehlungen zur Dringlichkeit<br><span class="lightgreycolor">Anteil Assessments mit angegebener
              Dringlichkeit an allen Assessments in Prozent</span>
          </h3>
          <app-plot [(data)]="this.timetotreat" [xvalue]="'TTTsmed_text'" [fontcolor]="'white'" [showlegend]="false"
            [outcomes]="['Anteil']" [custommargins]="{ l: 50, r: 0, b: 0, t: 0 }" plottype="hbar">
          </app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('empfehlung.csv',this.timetotreat)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Prozentuale Verteilung der SmED-Assessments nach der von SmED empfohlenen Versorgungsdringlichkeit.
                    Die Versorgungsdringlichkeit gibt an, innerhalb welcher Zeitspanne der Anrufer medizinisch versorgt
                    werden sollte.
                  </p>
                  <ol type="1">
                    <li>Notfall:</li>
                    <p>Es besteht ein potentiell lebensbedrohlicher Zustand. Die ärztliche Behandlung muss unverzüglich
                      erfolgen.</p>
                    <li>Schnellstmögliche ärztliche Versorgung:</li>
                    <p>Die ärztliche Behandlung soll schnellstmöglich erfolgen, muss jedoch nicht unmittelbar
                      stattfinden.</p>
                    <li>Ärztliche Behandlung innerhalb von 24 h:</li>
                    <p>Die ärztliche Behandlung soll innerhalb von 24 Stunden erfolgen.</p>
                    <li>Ärztliche Behandlung nicht innerhalb von 24 h erforderlich:</li>
                    <p>Die ärztliche Behandlung eilt nicht. Falls die Beschwerden in den nächsten 2 Tagen nicht
                      abklingen, ist eine ärztliche Behandlung in jedem Fall angezeigt.</p>
                  </ol>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=50 fxFlex.lt-md=100 *ngIf="this.stats_ts">
        <mat-card>
          <h3>Mittlere Dauer <br><span class="lightgreycolor">Dauer in Sekunden pro Assessment</span></h3>
          <app-plot [(data)]="this.stats_ts" xtickformat="%d.%m.<br>%Y" [xvalue]="'Datum'" [fontcolor]="'white'"
            [colorscheme]="[this.colorsscheme[3]]" [sortx]="true" [outcomes]="['Mittlere Dauer (Sek.)']"
            plottype="tsline"></app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('zeitreihe.csv',this.stats_ts)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Angaben zur Dauer liegen erst seit Mai 2020 vor. In die Berechnung der Dauer fließen nur
                    Assessments ein, die mit dem klassischen SmED-Modul (nicht COVID-spezifische Module) erfasst wurden.
                    Weiterhin wurden Assessments mit einer Dauer von über 1000 Sekunden ausgeschlossen, da bei diesen
                    Assessments davon auszugehen ist, dass diese nicht ordnungsgemäß abgeschlossen wurden.</p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=50 fxFlex.lt-md=100 *ngIf="this.stats_ts">
        <mat-card>
          <h3>Mittlere Anzahl Beschwerden<br><span class="lightgreycolor">Mittlere Anzahl unterschiedlicher Beschwerden
              pro Assessment</span></h3>
          <app-plot [(data)]="this.stats_ts" xtickformat="%d.%m.<br>%Y" [xvalue]="'Datum'" [fontcolor]="'white'"
            [colorscheme]="[this.colorsscheme[2]]" [sortx]="true" [outcomes]="['Mittlere Anzahl Beschwerden']"
            plottype="tsline"></app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('zeitreihe.csv',this.stats_ts)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Die Anzahl der Beschwerden umfasst die im Rahmen eines SmED-Assessments dokumentierten vorliegenden
                    Beschwerden der Anrufenden. Bisher nicht enthalten sind Beschwerden, die im Rahmen der Abfrage von
                    lebensbedrohlichen Situationen (Vortriage) erfasst wurden.</p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=100 fxFlex.lt-md=100 *ngIf="this.stats_ts && this.currentuser">
        <mat-card>
          <h3>Mittlere Anzahl Fragen<br><span class="lightgreycolor">Mittlere Anzahl beantworteter Fragen pro
              Assessment</span>
            <mat-icon class="backgroundicon">lock_open</mat-icon>
          </h3>
          <app-plot [(data)]="this.stats_ts" xtickformat="%d.%m.<br>%Y" [xvalue]="'Datum'" [fontcolor]="'white'"
            [colorscheme]="[this.colorsscheme[1]]" [sortx]="true" [outcomes]="['Mittlere Anzahl Fragen']"
            plottype="tsline"></app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('zeitreihe.csv',this.stats_ts)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Die Anzahl der Fragen umfasst die im Rahmen eines SmED-Assessments dokumentierten beantworteten
                    Fragen (inklusive Fragen zu Alter, Geschlecht, Schwangerschaft, lebensbedrohlicher Situation,
                    Beschwerden, chronischen Erkrankungen, Begleitumständen usw.).</p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=100 fxFlex.lt-md=100 *ngIf="this.currentuser && this.decisions_pocvsttt">
        <mat-card *ngIf="this.decisions_pocvsttt.length>0">
          <h3>Empfehlung Behandlungsort nach Dringlichkeit <br><span class="lightgreycolor">Anzahl Assessment<mat-icon
                class="backgroundicon">lock_open
              </mat-icon></span>
          </h3>
          <div>
            <app-plot [(data)]="this.decisions_pocvsttt" [xvalue]="'TTTsmed_text'" [fontcolor]="'white'"
              [showlegend]="false" [outcomes]="['Anzahl']" [custommargins]="{ l: 50, r: 50, b: 150, t: 0 }"
              plottype="stackedbar" [colorby]="'POCsmed_text'">
            </app-plot>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('timetotreat.csv',this.decisions_pocvsttt)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Prozentuale Verteilung der SmED-Assessments nach der von SmED empfohlenen Versorgungsebene
                    (Behandlungsort) und Versorgungsdringlichkeit.</p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=100 fxFlex.lt-md=100 *ngIf="this.timetotreat && this.currentuser">
        <mat-card *ngIf="this.timetotreat.length>0">
          <h3>SmED-Assessments nach QM-Kategorien<br><span class="lightgreycolor">Anteil Assessments in der angegebenen
              QM-Kategorie an allen Assessments in Prozent, basierend auf Daten seit 14.04.2023, ohne
              COVID-Module</span>
          </h3>
          <app-plot [(data)]="this.qmCases" [xvalue]="'text'" [fontcolor]="'white'" [showlegend]="false"
            [outcomes]="['data']" [custommargins]="{ l: 50, r: 0, b: 0, t: 0 }" plottype="hbar">
          </app-plot>
          <div>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('empfehlung.csv',this.qmCases)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Prozentuale Verteilung der SmED-Assessments nach QM-Kategorie.</p>
                  <ol type="1">
                    <li>Vortriage:</li>
                    <p>Im Rahmen der Abfrage einer lebensbedrohlichen Situation (Vortriage) wurde ein Notfall
                      festgestellt.</p>
                    <li>Nur Notfall ausgeschlossen:</li>
                    <p>Im Rahmen der Vortriage wurde ein Notfall ausgeschlossen, es wurde aber nicht weiter ins
                      Assessment eingestiegen.</p>
                    <li>Assessment vollständig:</li>
                    <p>SmED-Assessment durchgeführt, SmED-Empfehlung zur Dringlichkeit liegt vor, es wurden alle
                      farbigen Fragen beantwortet.</p>
                    <li>Assessment abgekürzt:</li>
                    <p>SmED-Assessment durchgeführt, SmED-Empfehlung zur Dringlichkeit liegt vor, jedoch nicht alle
                      farbigen Fragen beantwortet.</p>
                    <li>SmED nicht anwendbar:</li>
                    <p>Die Anwendung von SmED resultierte nicht in einer SmED-Empfehlung zur Dringlichkeit der
                      Behandlung. Dies kann folgende Gründe haben: (a) Es wurden zu wenige Angaben gemacht, (b) es
                      wurden zu viele unklare Antworten gegeben.</p>
                  </ol>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=50 fxFlex.lt-md=100 *ngIf="(this.currentuser && this.decisions_ttt) && (!this.isExtern)">
        <mat-card *ngIf="this.decisions_ttt.length>0">
          <h3>Abgleich Empfehlung vs. Entscheidung Dringlichkeit<br><span class="lightgreycolor">Anzahl
              Assessment<mat-icon class="backgroundicon">lock_open
              </mat-icon></span>
          </h3>
          <div>
            <app-plot [(data)]="this.decisions_ttt" [xvalue]="'TTTsmed_text'" [fontcolor]="'white'" [showlegend]="true"
              [outcomes]="['Anzahl']" [custommargins]="{ l: 50, r: 50, b: 150, t: 0 }" plottype="stackedbar"
              [colorby]="'TTTdispo_text'">
            </app-plot>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('decisions_ttt.csv',this.decisions_ttt)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Abgleich der SmED-Empfehlung mit der Entscheidung des/der Disponent*in hinsichtlich der
                    Versorgungsdringlichkeit.
                    Die Entscheidung des/der Disponent*in wird in SmED bisher nur bei einem kleinen Anteil der
                    SmED-Assessments dokumentiert.
                    Es wird an einer Übermittlung der entsprechenden Angaben aus der Dispositionssoftware gearbeitet.
                    Dieser Prozess ist bisher
                    mit einigen wenigen KVen etabliert. </p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
      <div fxFlex=50 fxFlex.lt-md=100 *ngIf="(this.decisions_poc && this.currentuser) && (!this.isExtern)">
        <mat-card *ngIf="this.decisions_poc.length>0">
          <h3>Abgleich Empfehlung vs. Entscheidung Behandlungsort <br><span class="lightgreycolor">Anzahl
              Assessment<mat-icon class="backgroundicon">lock_open
              </mat-icon></span>
          </h3>
          <div>
            <app-plot [(data)]="this.decisions_poc" [xvalue]="'POCsmed_text'" [fontcolor]="'white'" [showlegend]="true"
              [outcomes]="['Anzahl']" [custommargins]="{ l: 50, r: 50, b: 150, t: 0 }" plottype="stackedbar"
              [colorby]="'POCdispo_text'">
            </app-plot>
            <mat-expansion-panel (opened)="panelOpenState = true" class="mat-elevation-z0"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-icon *ngIf="!panelOpenState">info_outline</mat-icon>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="mat-caption">
                  <p *ngIf="this.currentuser">
                    <a (click)="this.exportascsv('decisions_poc.csv',this.decisions_poc)">
                      <mat-icon inline=true>file_download</mat-icon>
                      Angezeigte Daten als CSV-Datei herunterladen &nbsp;
                    </a>
                  </p>
                  <h3> Weitere Informationen zum Indikator</h3>
                  <p>Abgleich der SmED-Empfehlung mit der Entscheidung des/der Disponent*in hinsichtlich der
                    Versorgungsebene.
                    Die Entscheidung des/der Disponent*in wird in SmED bisher nur bei einem kleinen Anteil der
                    SmED-Assessments dokumentiert.
                    Es wird an einer Übermittlung der entsprechenden Angaben aus der Dispositionssoftware gearbeitet.
                    Dieser Prozess ist bisher
                    mit einigen wenigen KVen etabliert. </p>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>