<mat-sidenav-container autosize="true" scrollable="false">
  <mat-sidenav-content>
    <mat-toolbar color="transparent">
      <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
        aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button>
      <!-- <span>{{this.title}}</span> -->
      <span class="example-spacer"></span>
      <div fxShow="true" fxHide.lt-sm="true">
        <a href="https://www.zi.de/smed">
          <img src="./assets/branding/smed_logo.png" class="brandinglogo">
        </a>
        <a href="https://www.zi.de">
          <img src="./assets/branding/zi_Logo.png"
            class="brandinglogo">
        </a>
      </div>
      <div fxShow="false" fxShow.lt-sm="true">
        <a href="https://www.zi.de">
          <img src="./assets/branding/zi_Logo.png" class="brandinglogo">
        </a>
      </div>
    </mat-toolbar>
    <main>
      <section class="mat-typography">
        <mat-drawer-container autosize>
          <mat-drawer #drawer mode="push" position="start" autoFocus="false">
            <div class="menucontainer">
              <ng-container>
                <p class="primarycolor"><strong>Navigation</strong></p>
              </ng-container>
              <div>
                <a mat-icon-button (click)="drawer.toggle()" routerLink="/">
                  <mat-icon class="icon">home</mat-icon>
                  <span>Startseite</span>
                </a>
              </div>
              <div *ngIf="this.loginoption && !this.loginstatus">
                <a mat-icon-button (click)="drawer.toggle()" routerLink="/login">
                  <mat-icon class="icon">login</mat-icon>
                  <span>Anmelden</span>
                </a>
              </div>
              <div *ngIf="this.loginstatus">
                <a mat-icon-button (click)="drawer.toggle();" routerLink="/profile">
                  <mat-icon class="icon">account_box</mat-icon>
                  <span>Benutzerprofil</span>
                </a>
              </div>
              <div *ngIf="this.currentuser && !this.isExtern">
                <a mat-icon-button (click)="drawer.toggle();" routerLink="/private">
                  <mat-icon class="icon">dashboard</mat-icon>
                  <span>Erweiterte Analysen</span>
                </a>
              </div>
              <div *ngIf="this.adminstatus">
                <a mat-icon-button (click)="drawer.toggle();" routerLink="/admin">
                  <mat-icon class="icon">admin_panel_settings</mat-icon>
                  <span>Administration</span>
                </a>
              </div>
              <div *ngIf="this.loginstatus">
                <a mat-icon-button (click)="drawer.toggle();logout();" href="./">
                  <mat-icon class="icon">logout</mat-icon>
                  <span>Ausloggen</span>
                </a>
              </div>
            </div>
          </mat-drawer>
          <mat-drawer-content>
            <div class="offlinemsg" *ngIf="false"><strong>Keine Internetverbindung</strong><br>Dieses Portal benötigt
              eine aktive Internetverbindung.</div>
            <div class="offlinemsg" *ngIf="this.apiconnection==2"><strong>Keine Verbindung zur API</strong><br>Dieses
              Portal benötigt
              eine aktive Internetverbindung.</div>
            <router-outlet></router-outlet>
          </mat-drawer-content>
        </mat-drawer-container>
      </section>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- Footer nur für Desktop Screen -->
<footer>
  <div class="footerstyle">
    <div fxLayout="column" fxLayoutGap="0.5rem">
      <div fxLayout="column wrap" fxLayoutAlign="space-around start">
        <div fxflex=28 fxHide.xs>
          <p>
            <mat-list style="color:white;">
              <mat-list-item>
                <p style="color:white;"><strong>Navigation</strong></p>
              </mat-list-item>
              <mat-list-item><a style="color:white;" href="https://www.zi.de/meta/impressum">Impressum</a>
              </mat-list-item>
              <mat-list-item><a style="color:white;"
                  href="https://www.zi.de/footer/datenschutzhinweis">Datenschutzhinweise</a></mat-list-item>
            </mat-list>
          </p>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="space-between end">
          <div fxFlex>
            <br>
          </div>
          <div style="padding:0.5rem;text-align: center;">
            <p>© {{this.currentdate | date:'Y'}} Zentralinstitut für die kassenärztliche Versorgung in der
              Bundesrepublik
              Deutschland</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>