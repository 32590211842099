<ng-container *ngIf="this.addfilter">
  <mat-form-field appearance="outline" >
    <mat-label>Suche</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Suche..." #input>
  </mat-form-field>
</ng-container>

<mat-table [(dataSource)]="this.datasource" matSort class="mat-elevation-z0">

 <ng-container *ngFor="let thecol of displayedColumns">
  <ng-container [matColumnDef]="thecol">
    <mat-header-cell *matHeaderCellDef mat-sort-header> {{thecol}} </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
      <span *ngIf="this.outcomes_rate.indexOf(thecol)>=0">{{element[thecol] | percent }}</span>
      <span *ngIf="this.outcomes_numeric.indexOf(thecol)>=0">{{element[thecol] | number }}</span>
      <span *ngIf="(this.outcomes_rate.indexOf(thecol)<0) && (this.outcomes_numeric.indexOf(thecol)<0)">{{element[thecol]}}</span>
    </mat-cell >
  </ng-container>
</ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="this.pagesizes" showFirstLastButtons></mat-paginator>