<div fxLayout="row wrap">
  <div fxFlex=100>
    <!-- {{ data | json }} -->
    <h2><strong>Nutzer Bearbeiten</strong></h2>
    <mat-card>
      <form autocomplete="on" [formGroup]="myRegform">
        <h2><strong>Informationen</strong></h2>
        <h4><strong>Name</strong>:</h4>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Anrede</mat-label>
          <mat-select name="anredefeld" formControlName="anrede">
            <mat-option *ngFor="let salutation of salutations" [value]="salutation">
              {{ salutation === ' ' ? 'keine Anrede' : salutation }}
            </mat-option>
          </mat-select>
          <mat-error>Bitte wählen Sie eine bevorzugte Anrede für sich</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Vorname</mat-label>
          <input matInput placeholder="First name" name="fname" formControlName="firstname">
          <mat-error>Fehler: Wir benötigen den Vornamen für die Registrierung.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width" required>
          <mat-label>Nachname</mat-label>
          <input matInput placeholder="Last Name" name="lname" formControlName="lastname">
          <mat-error>Fehler: Wir benötigen den Nachnamen für die Registrierung.</mat-error>
        </mat-form-field>
        <h4><strong> E-Mail</strong></h4>
        <mat-form-field appearance="fill" class="full-width">
          <input matInput type="email" placeholder="Email" formControlName="email">
          <mat-icon matSuffix>email</mat-icon>
          <mat-error>Die E-Mail-Adresse ist ungültig</mat-error>
        </mat-form-field>
        <div *ngIf="!isPasswordChange">
          <button mat-raised-button (click)="togglePasswordChange()">Passwort ändern</button>
        </div>
        <div *ngIf="isPasswordChange">
          <mat-form-field appearance="fill" class="full-width">
            <input matInput type="password" placeholder="Passwort" formControlName="password">
          </mat-form-field>
          <div style="font-size: small; font-style: italic; color: gray;">Passwort muss 8 Zeichen lang sein, min. einen
            Groß.- und Kleinbuchstaben, eine Zahl und ein
            Sonderzeichen enthalten.</div>
          <button mat-raised-button (click)="togglePasswordChange()">Abbrechen</button>
          <button mat-raised-button (click)="changePassword()">Ändern</button>
        </div>
        <h4><strong>Rechte</strong></h4>
        <mat-chip-list class="smallchips">
          <mat-chip color="primary" *ngFor="let right of userRights" [value]="right.value"
            (click)="updateUserRole('role', data, 'user', right.value)">
            <mat-icon matListIcon *ngIf="right.selected">check</mat-icon>
            {{ right.name }}
          </mat-chip>
        </mat-chip-list>
        <h4><strong>Daten-Level</strong></h4>
        <mat-chip-list class="smallchips">
          <mat-chip color="primary" *ngFor="let dataLevel of userGroupOptions" [value]="dataLevel.value"
            (click)="updateUserDataLevel('dataLevel', data.email, 'usergroups', dataLevel.value)">
            <mat-icon matListIcon *ngIf="dataLevel.selected">check</mat-icon>
            {{ dataLevel.name }}
          </mat-chip>
        </mat-chip-list>
      </form>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="space-between">
    <!-- <button mat-raised-button (click)="onNoClick()" color="warn">Abbrechen</button> -->
    <button mat-raised-button (click)="onNoClick()" color="primary">Schließen</button>
  </div>
</div>